import {
  setUserToken,
  removeUserToken,
  setAdminToken,
  removeAdminToken,
} from '@/utils';

import {
  api,
  paths,
} from '@/api';

const state = {
  isAdmin: false,
  isUserImpersonated: false,
};

const envHostUrl = process.env.VUE_APP_HOST_URL ?? 'https://partner.idealagent.com';

const getters = {
  noActiveUser: state => state.isAdmin && !state.isUserImpersonated,
};

const actions = {
  async login(context, payload) {
    const { data } = await api.post(paths.USER_LOGIN, payload, {
      noToken: true,
    });
    const { user, token } = data;
    if (user.employeeId) {
      setAdminToken(token);
    } else {
      setUserToken(token);
    }
    return data;
  },
  async mailCheckLogin(context, email) {
    const { data } = await api.get(`${paths.MAIL_CHECK_LOGIN}/${email}`, {
      noToken: true,
    });
    return data;
  },
  async requestResetPassword(context, payload) {
    const path = `${paths.USER_RESET_PASSWORD_REQUEST}?resetTokenUrl=${envHostUrl}`;
    const { data } = await api.post(path, payload, {
      noToken: true,
    });
    return data;
  },
  async logout({ state }) {
    if (state.isAdmin) {
      const { data } = await api.post(paths.USER_LOGOUT, null, {
        tokenType: 'admin',
      });
      removeUserToken();
      removeAdminToken();
      return data;
    }
    const { data } = await api.post(paths.USER_LOGOUT);
    removeUserToken();
    return data;
  },
  async createAccount(context, payload) {
    const { data } = await api.post(paths.CREATE_ACCOUNT, payload, {
      noToken: true,
    });
    return data;
  },
  async checkResetToken(context, code) {
    const { data } = await api.get(`${paths.USER_RESET_PASSWORD_CHECK_TOKEN}/${code}`, {
      noToken: true,
    });
    return data;
  },
  async resetPassword(context, payload) {
    const { data } = await api.post(paths.USER_RESET_PASSWORD, payload, {
      noToken: true,
    });
    return data;
  },
  setAdminFlags({ commit }, data) {
    commit('setAdmin', data.setAdmin);
    commit('setIsUserSelected', data.setIsUserSelected);
  },

};

const mutations = {
  setAdmin(state, value) {
    state.isAdmin = value;
  },
  setIsUserSelected(state, value) {
    state.isUserImpersonated = value;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
