import {
  Settings,
  LeadDetail,
  SearchResults,
  Dashboard,
  UserImpersonation,
  MarketingMaterials,
  FAQ,
  Help,
} from '@/views';
import { redirectionErrors } from './constants';

export const primaryLayoutChildren = [
  {
    path: '/',
    name: 'Dashboard',
    components: {
      default: Dashboard,
    },
  },
  {
    path: '/settings',
    name: 'Settings',
    components: {
      default: Settings,
    },
  },
  {
    path: '/marketing-materials',
    name: 'Marketing Materials',
    props: true,
    components: {
      default: MarketingMaterials,
    },
  },
  {
    path: '/faq',
    name: 'FAQ',
    components: {
      default: FAQ,
    },
  },
  {
    path: '/help',
    name: 'Help',
    components: {
      default: Help,
    },
  },
  {
    path: '/referral/:id',
    name: 'Referral',
    redirect: () => {
      const params = { redirectionError: redirectionErrors.clientNotFound };
      return { name: 'My Referrals', params };
    },
  },
  {
    path: '/property/:id',
    name: 'Property',
    components: {
      default: LeadDetail,
    },
  },
  {
    path: '/buyer/:id',
    name: 'Buyer',
    components: {
      default: LeadDetail,
    },
  },
  {
    path: '/my-referrals',
    name: 'My Referrals',
    props: true,
    components: {
      default: SearchResults,
    },
  },
  {
    path: '/user-impersonation',
    name: 'User impersonation',
    components: {
      default: UserImpersonation,
    },
    meta: {
      requiresAdmin: true,
    },
  },

];
