<template>
  <!-- eslint-disable max-len -->
  <div class="flex flex-1 overflow-hidden">
    <!-- Secondary sidebar -->
    <aside
      class="flex xl:flex flex-col flex-shrink-0 w-full sm:w-96 border-r border-gray-200"
      :class="{ 'xs:hidden sm:hidden': !filtersShown }"
    >
      <search-filter-controls
        :show-filter-header="filtersShown"
        @switch-filters="switchFilters"
      />
    </aside>

    <main
      class="flex flex-col flex-1 overflow-hidden"
      tabindex="0"
    >
      <breadcrumbs
        label="Filters"
        class="xl:hidden"
        @click="switchFilters(true)"
      />

      <div class="flex justify-between bg-gray-300 p-4 md:px-12">
        <search-order-controls />
        <search-hide-controls />
      </div>

      <!-- Results -->
      <article
        id="search-results-container"
        class="flex flex-col flex-1 overflow-y-auto bg-gray-300 p-4 pb-0 sm:px-8 md:px-12 scroll-smooth"
      >
        <spinner
          v-if="loading.details"
          fullscreen
        />
        <ul
          v-else
        >
          <li
            v-for="(client, index) in clientsToDisplay"
            :id="`profile-${client.id}`"
            :key="index"
            @click="toDetail(client)"
          >
            <referral-card
              :ref="client.id"
              :client="client"
            />
          </li>
        </ul>
        <scroll-to-top container-id="search-results-container" />
      </article>
    </main>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { getClickId, setClickId } from '@/utils/localStorageControls';
import {
  SearchFilterControls,
  SearchHideControls,
  SearchOrderControls,
  ReferralCard,
  ScrollToTop,
  Spinner,
  Breadcrumbs,
} from '@/components';
import {
  formatPhone,
  formatPrice,
  getTags,
  getReferralPageUrl,
} from '@/utils';

export default {
  name: 'SearchResults',
  components: {
    SearchFilterControls,
    ScrollToTop,
    ReferralCard,
    Spinner,
    Breadcrumbs,
    SearchHideControls,
    SearchOrderControls,
  },
  data: () => ({
    filtersShown: false,
  }),
  computed: {
    ...mapState('agent', ['loading']),
    ...mapGetters('filters', ['filteredClients']),
    clientsToDisplay: vm => vm.filteredClients.map(client => {
      const prices = {
        listPrice: vm.getFormattedPrice(client.listPrice),
        estListPrice: vm.getFormattedPrice(client.estListPrice),
        maxBudget: vm.getFormattedPrice(client.maxBudget),
      };
      return {
        ...client,
        ...prices,
        mobile: formatPhone(client.mobile),
        tags: getTags(client),
        lastEvent: vm.setLastEvent(client),
      };
    }),
  },
  created() {
    const { redirectionError } = this.$route.params;
    if (redirectionError) this.$toast.error(redirectionError);
  },
  mounted() {
    const savedClickId = getClickId();
    if (savedClickId) {
      this.scrollToId(savedClickId);
    }
  },
  methods: {
    setLastEvent(lead) {
      switch (true) {
        case lead.soldDate:
          return { label: 'Sold on', dateStr: lead.soldDate };
        case lead.activeListingDate:
          return { label: 'Appointment on', dateStr: lead.activeListingDate };
        case lead.listingApptDate:
          return { label: 'Listed on', dateStr: lead.listingApptDate };
        default:
          return { label: 'Referred on', dateStr: lead.exchangeDate };
      }
    },
    getFormattedPrice(price) {
      if (!price) return null;
      const formattedPrice = formatPrice(price);
      return +formattedPrice[1] ? formattedPrice : null;
    },
    scrollToId(id) {
      const div = document.getElementById('search-results-container');
      const elm = document.getElementById(`profile-${id}`);
      div.scrollTo({
        top: elm.offsetTop - 105,
        behavior: 'smooth',
      });
    },
    toDetail(lead) {
      const referralUrl = getReferralPageUrl(lead);
      setClickId(lead.id);
      this.$router.push(referralUrl);
    },
    switchFilters(shouldDisplay) {
      this.filtersShown = shouldDisplay;
    },
  },
};
</script>
