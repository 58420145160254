<template>
  <div>
    <t-alert
      v-if="isSubmitted"
      :dismissible="false"
      variant="success"
      show
    >
      Form successfully updated!
    </t-alert>

    <template v-else>
      <spinner v-if="isFetchingFields" />
      <template v-else>
        <div class="text-base">
          Please provide an update on the current status of {{ fullName }}
        </div>
        <form @submit.prevent="handleSubmit">
          <!-- Buyer progress dropdown -->
          <div class="pb-5">
            <c-dropdown
              v-model="$v.buyerProgress.$model"
              class="mt-3 border-gray-300"
              variant="demo"
              :options="buyerProgressOptions"
              @input="clearFields"
            />
          </div>
          <!-- Next follow up date datepicker and checkbox -->
          <div
            v-show="nextFollowUpShown"
            class="pb-5"
          >
            <p>Next Follow Up Date:</p>
            <div class="flex items-center mt-3">
              <c-date-picker
                v-model="$v.nextFollowUp.$model"
                class="flex-auto"
                close-on-select
                date-format="Y-m-d"
                :min-date="new Date()"
                :error-message="getDirtyErrorMessage('nextFollowUp')"
              />
            </div>
          </div>
          <!-- Details about the buyer update - textarea-->
          <div
            v-show="notesShown"
            class="pb-5"
          >
            <p>Please provide details about the buyer update:</p>
            <c-textarea-input
              v-model="$v.notes.$model"
              :error-message="getDirtyErrorMessage('notes')"
            />
          </div>
          <!-- Address info -->
          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p class="mb-3">
              Address:
            </p>
            <address-autocomplete
              :value="placeName"
              :error-message="getDirtyErrorMessage('placeName')"
              @place-changed="updateAddress"
            />
          </div>

          <!--Contract date -->
          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Contract Date:</p>
            <div class="flex items-center">
              <c-date-picker
                v-model="$v.contractDate.$model"
                class="flex-auto"
                close-on-select
                date-format="Y-m-d"
                :error-message="getDirtyErrorMessage('contractDate')"
              />
            </div>
          </div>
          <!-- Scheduled Closing date -->
          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Scheduled Closing Date:</p>
            <div class="flex items-center">
              <c-date-picker
                v-model="$v.closingDate.$model"
                class="flex-auto"
                close-on-select
                date-format="Y-m-d"
                :error-message="getDirtyErrorMessage('closingDate')"
              />
            </div>
          </div>
          <!-- Contract price field -->
          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>
              Contract Price:
            </p>
            <c-text-input
              v-model.number="$v.contractPrice.$model"
              type="currency"
              placeholder="Contract Price"
              :error-message="getDirtyErrorMessage('contractPrice')"
            />
          </div>

          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Commission Reduction Amount</p>
            <c-text-input
              v-model.number="$v.commissionReduction.$model"
              type="currency"
              placeholder="Commission Reduction Amount"
              :error-message="getDirtyErrorMessage('commissionReduction')"
            />
          </div>

          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Transaction Fee Amount</p>
            <c-text-input
              v-model.number="$v.transactionFee.$model"
              type="currency"
              placeholder="Transaction Fee Amount"
              :error-message="getDirtyErrorMessage('transactionFee')"
            />
          </div>

          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Other Fee Amount</p>
            <c-text-input
              v-model.number="$v.otherFee.$model"
              type="currency"
              placeholder="Other Fee Amount"
              :error-message="getDirtyErrorMessage('otherFee')"
            />
          </div>

          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Buying Agent Commission Percentage:</p>
            <c-text-input
              v-model.number="$v.buyerCommissionPercent.$model"
              type="percentage"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Buying Agent Commission percentage"
              :error-message="getDirtyErrorMessage('buyerCommissionPercent')"
            />
          </div>

          <div
            v-show="acceptedFieldsShown"
            class="pb-5"
          >
            <p>Selling Agent Commission Percentage:</p>
            <c-text-input
              v-model.number="$v.listingCommissionPercent.$model"
              type="percentage"
              class="block w-full sm:text-sm rounded-md"
              placeholder="Selling Agent Commission percentage"
              :error-message="getDirtyErrorMessage('listingCommissionPercent')"
            />
          </div>
          <!-- Readonly Calculator values -->
          <preliminary-statement-calculator
            v-show="acceptedFieldsShown"
            :values="statementCalculatorFields"
            :is-loading="isFetchingCalculator"
            class="mb-5"
          />
          <!-- Controls -->
          <div
            v-if="buyerProgress"
            class="mb-5 flex"
          >
            <c-button
              :is-loading="isSubmitting"
              type="submit"
            >
              Save
            </c-button>
            <contract-fell-through
              v-if="(shouldDisplayCFT && address)"
              class="ml-5"
              :address="address"
              profile-type="buyer"
              @confirm="clearFields"
            />
            <generate-statement
              v-if="generateStatementButtonReady && contractPrice > 0"
              :id="client.id"
              class="ml-5"
              :is-seller="false"
              :file-name="statementFileName"
              :query-string="query"
            />
          </div>
        </form>
      </template>
    </template>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { formMixin } from '@/mixins';
import { required, requiredIf } from 'vuelidate/lib/validators';
import debounce from 'lodash/debounce';
import {
  greaterThanZero,
  debounceInterval,
  commissionPercent,
  validDate,
} from '@/constants';

import {
  CDatePicker,
  CDropdown,
  CTextareaInput,
  CTextInput,
  AddressAutocomplete,
} from '@/components/inputs';
import { CButton } from '@/components/controls';
import PreliminaryStatementCalculator from '@/components/forms/PreliminaryStatementCalculator.vue';
import Spinner from '@/components/Spinner.vue';
import ContractFellThrough from '@/components/ContractFellThrough.vue';
import GenerateStatement from '@/components/GenerateStatement.vue';
import {
  buyerProgressOptions,
  buyerProgressStates,
  bufFields,
  conditionalTypeByField,
  operationByProgress,
  prefillFields,
} from './constants';

export default {
  name: 'BufForm',
  components: {
    CDatePicker,
    CDropdown,
    CTextInput,
    CTextareaInput,
    CButton,
    GenerateStatement,
    ContractFellThrough,
    AddressAutocomplete,
    Spinner,
    PreliminaryStatementCalculator,
  },
  mixins: [formMixin],
  props: {
    client: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    prefillFields,
    buyerProgressStates,
    conditionalTypeByField,
    fields: bufFields,
    isSubmitted: false,
    isSubmitting: false,
    buyerProgress: null,
    nextFollowUp: '',
    notes: '',
    placeName: '', // an address formatted for displaying to the user, not being sent to the BE
    address: '', // this one is being sent to the BE
    zip: '',
    state: '',
    city: '',
    contractDate: null,
    closingDate: '',
    contractPrice: 0,
    transactionFee: 0,
    commissionReduction: 0,
    otherFee: 0,
    buyerCommissionPercent: 0,
    listingCommissionPercent: 0,
    isFetchingFields: false,
    isFetchingCalculator: false,
    generateStatementButtonReady: false,
    statementCalculatorFields: {
      totalToBrokerage: null,
      referralFee: null,
      referralFeeAmount: null,
    },
    statementFileName: '',
    addressFields: ['zip', 'city', 'state', 'address', 'placeName'],
  }),
  computed: {
    allowSave: vm => vm.listingCommissionPercent + vm.buyerCommissionPercent > 0,
    calculateReady: vm => validDate(vm.contractDate)
      && validDate(vm.closingDate)
      && greaterThanZero(vm.contractPrice)
      && (vm.listingCommissionPercent + vm.buyerCommissionPercent > 0),
    fullName: vm => `${vm.client.firstName} ${vm.client.lastName}`,
    shouldDisplayCFT: vm => vm.client.buyerStatus === 'PENDING',
    shouldDisplayLostOption: vm => vm.client.buyerStatus === 'CONNECT' || vm.client.buyerStatus === 'SHOWING_PROPERTIES' || vm.client.buyerStatus === 'NOT_READY',
    nextFollowUpShown: vm => {
      const states = [
        vm.buyerProgressStates.showingProperties,
        vm.buyerProgressStates.notReady,
      ];
      return states.includes(vm.buyerProgress);
    },
    notesShown: vm => {
      const { showingProperties, notReady, lost } = vm.buyerProgressStates;
      const states = [showingProperties, notReady, lost];
      return states.includes(vm.buyerProgress);
    },
    acceptedFieldsShown: vm => vm.buyerProgress === vm.buyerProgressStates.offerAccepted,
    fieldConditions: vm => ({
      nextFollowUp: vm.nextFollowUpShown,
      notes: vm.notesShown,
      contractDate: vm.acceptedFieldsShown,
      closingDate: vm.acceptedFieldsShown,
      contractPrice: vm.acceptedFieldsShown,
      buyerCommissionPercent: vm.acceptedFieldsShown,
      listingCommissionPercent: vm.acceptedFieldsShown,
      zip: vm.acceptedFieldsShown,
      state: vm.acceptedFieldsShown,
      city: vm.acceptedFieldsShown,
      address: vm.acceptedFieldsShown,
    }),
    operation: vm => operationByProgress[vm.buyerProgress],
    buyerProgressOptions() {
      const options = [...buyerProgressOptions];
      if (this.shouldDisplayLostOption) options.push(this.buyerProgressStates.lost);
      return options;
    },
    query: vm => vm.buildQueryStringParams(vm),
  },

  validations() {
    return {
      buyerProgress: { required },
      nextFollowUp: {
        required: requiredIf(() => this.nextFollowUpShown),
      },
      notes: {
        required: requiredIf(() => this.notesShown),
      },
      placeName: {
        required: requiredIf(() => this.acceptedFieldsShown),
      },
      contractDate: {
        required: requiredIf(() => this.acceptedFieldsShown),
        validDate: !this.acceptedFieldsShown || validDate,
      },
      closingDate: {
        required: requiredIf(() => this.acceptedFieldsShown),
        validDate: !this.acceptedFieldsShown || validDate,
      },
      commissionReduction: {
        required: requiredIf(() => this.acceptedFieldsShown),
      },
      otherFee: {
        required: requiredIf(() => this.acceptedFieldsShown),
      },
      transactionFee: {
        required: requiredIf(() => this.acceptedFieldsShown),
      },
      contractPrice: {
        required: requiredIf(() => this.acceptedFieldsShown),
        greaterThanZero: !this.acceptedFieldsShown || greaterThanZero,
      },
      listingCommissionPercent: {
        required: requiredIf(() => this.acceptedFieldsShown),
        commissionPercent: !this.acceptedFieldsShown || commissionPercent,
      },
      buyerCommissionPercent: {
        required: requiredIf(() => this.acceptedFieldsShown),
        commissionPercent: !this.acceptedFieldsShown || commissionPercent,
      },
    };
  },
  watch: {
    query: debounce(function debounced() {
      this.fetchStatementCalculator();
    }, debounceInterval),
  },
  // async created() {
  //   if (this.client.buyerStatus === 'OFFER') {
  //     await this.fetchFields();
  //   }
  // },
  async mounted() {
    await Promise.all([this.fetchFields(), this.setFileName()]);
  },
  methods: {
    ...mapActions('client', ['saveBuf', 'fetchBuf', 'fetchStatementCalculation', 'getStatementFileNameFromClient']),
    ...mapActions('agent', ['getAgentDetails']),
    buildQueryStringParams(state) {
      const params = new URLSearchParams({
        contractPrice: state.contractPrice,
        commissionReduction: state.commissionReduction,
        transactionFee: state.transactionFee,
        otherFee: state.otherFee,
        scheduledClosingDate: state.closingDate,
        address: state.address,
        city: state.city,
        state: state.state,
        zip: state.zip,
        buyerCommissionPercent: state.buyerCommissionPercent,
        listingCommissionPercent: state.listingCommissionPercent,
      });
      return params.toString();
    },
    async handleSubmit() {
      if (this.isValid()) {
        const payload = {
          agentId: +this.client.assignedAgent,
          searchId: this.client.buyerProfileReferralId,
          buyerStatus: this.client.buyerStatus,
          ...this.generatedPayload,
          otherFee: this.otherFee,
          transactionFee: this.transactionFee,
          commissionReduction: this.commissionReduction,
          buyerCommissionPercent: this.buyerCommissionPercent,
          listingCommissionPercent: this.listingCommissionPercent,
        };
        try {
          this.isSubmitting = true;
          await this.saveBuf(payload);
          this.$emit('submit');
          this.isSubmitted = true;

          setTimeout(this.getAgentDetails, 3000);
        } catch ({ message }) {
          this.$toast.error(message);
        } finally {
          this.isSubmitting = false;
        }
      }
    },
    updateAddress(address) {
      this.addressFields.forEach(field => {
        this[field] = address[field];
      });
    },
    isValid() {
      if (!this.$v.$dirty) this.$v.$touch();
      return !(this.$v.$invalid);
    },
    async fetchFields() {
      if (!this.acceptedFieldsShown) return false;
      const params = { id: this.client.id };
      try {
        this.isFetchingFields = true;
        const response = await this.fetchBuf(params);
        debugger;

        this.prefillFields.forEach(field => {
          if (response[field]) { // to avoid console errors because those fields can be null
            this[field] = response[field];
          }
        });
        this.address = response.street;

        const address = this.addressFields.reduce((acc, field) => {
          if (this[field]) acc.push(this[field]);
          return acc;
        }, []);
        this.placeName = address.join(', ');
        this.buyerProgress = 'Offer Accepted';
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingFields = false;
      }
    },
    async fetchStatementCalculator() {
      if (this.calculateReady) {
        try {
          this.isFetchingCalculator = true;
          const { id } = this.client;
          const queryString = this.buildQueryStringParams(this);
          this.statementCalculatorFields = await this.fetchStatementCalculation({ id, queryString, isSeller: false });
          this.generateStatementButtonReady = true;
        } catch ({ message }) {
          this.$toast.error(message);
        } finally {
          this.isFetchingCalculator = false;
        }
      }
    },
    async setFileName() {
      this.statementFileName = await this.getStatementFileNameFromClient(this.client);
    },
    clearFields() {
      this.nextFollowUp = '';
      this.notes = '';
      this.placeName = '';
      this.address = '';
      this.zip = '';
      this.state = '';
      this.city = '';
      this.contractDate = null;
      this.closingDate = null;
      this.contractPrice = 0;
      this.commissionReduction = 0;
      this.transactionFee = 0;
      this.otherFee = 0;
      this.totalCommissionPercent = 0;
      this.buyerCommissionPercent = 0;
      this.listingCommissionPercent = 0;
      this.generateStatementButtonReady = false;
      this.statementCalculatorFields = {
        totalToBrokerage: null,
        referralFee: null,
        referralFeeAmount: null,
      };

      // Had to use nextTick here because comments were still considered dirty
      this.$nextTick(() => this.$v.$reset());
    },
  },
};
</script>
