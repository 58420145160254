<template>
  <div class="flex flex-1 overflow-hidden">
    <!-- Sidebar with names -->
    <aside class="hidden xl:flex flex-col border-r border-gray-200">
      <search-filter-controls :hide-search-filters="true" />
    </aside>
    <!-- Main content with loading spinner -->
    <spinner
      v-if="isLoadingClient"
      fullscreen
    />
    <main
      v-else
      class="flex flex-col flex-1 overflow-y-auto bg-gray-50"
    >
      <breadcrumbs
        label="My Referrals"
        class="lg:hidden"
        @click="$router.push('/my-referrals')"
      />

      <article class="flex-1 overflow-auto">
        <!-- Blue banner -->
        <div class="hidden lg:block bg-gradient-to-r from-blue-700 to-blue-500 h-32 lg:h-24" />
        <div class="max-w-5xl mx-auto px-6 lg:px-8">
          <!-- Profile header -->
          <div class="pt-6">
            <!--Action buttons-->
            <div class="flex justify-start sm:justify-end items-center">
              <a
                :href="`mailto:${emailAddress}`"
                class="inline-flex justify-center px-4 py-2 border mr-2 border-gray-400 sm:border-none hover:border-gray-4 text-sm rounded-md hover:bg-gray-50 focus:outline-none"
              >
                <mail-icon
                  :class="iconsClass"
                  color="blue-500"
                />
                <span>Message</span>
              </a>
              <a
                :href="`tel:${phoneNumber}`"
                class="inline-flex justify-center px-4 py-2 border border-gray-400 sm:border-none hover:border-gray-300 text-sm rounded-md hover:bg-gray-50 focus:outline-none"
              >
                <phone-icon
                  :class="iconsClass"
                  color="green-500"
                />
                <span>Call</span>
              </a>
            </div>
            <div class="flex flex-col sm:flex-row">
              <!--Basic lead info -->
              <div class="mt-6 mb-8 min-w-0 flex-1">
                <div class="flex items-center">
                  <h1 class="text-2xl font-bold text-gray-900 truncate mr-3">
                    {{ fullName }}
                  </h1>
                  <p
                    v-if="client.clientType"
                    class="text-xs leading-5 font-semibold rounded-full border-2 px-2"
                    :class="`bg-${clientTypeColor}-100 text-${clientTypeColor}-800 border-${clientTypeColor}-300`"
                    v-text="client.clientType"
                  />
                </div>
                <div class="py-1">
                  <p
                    v-for="tag in displayedTags"
                    :key="tag.name"
                    class="my-1 mr-2 px-2 inline-flex text-xs leading-5 font-semibold rounded-full border-2"
                    :class="`bg-${ tag.color }-100 text-${ tag.color }-800 border-${ tag.color }-300`"
                    v-text="tag.name"
                  />
                  <c-link
                    v-if="isSold && iPayText"
                    link="https://idealagent.ingageipay.com/Account/SignIn.aspx"
                    class-link="px-4 py-1 md:m-0 md:px-3 md:py-1 bg-blue-900 sm:text-xs text-white font-semibold uppercase rounded-full hover:bg-blue-800 hover:underline"
                    :text="iPayText"
                    target="_blank"
                  />
                </div>
                <div
                  v-if="client.needUpdateReason && !formSubmitted"
                  class="text-red-600 mt-2 text-left sm:text-center md:text-left text-sm whitespace-normal mb-2"
                  v-text="client.needUpdateReason"
                />
                <div
                  v-for="entry in infoToDisplay"
                  :key="entry.name"
                  class="flex"
                >
                  <template v-if="entry.display">
                    <component
                      :is="entry.icon"
                      :class="iconsClass"
                      color="gray-500"
                      class="flex-shrink-0 mb-2"
                    />
                    <p class="text-sm truncate">
                      {{ entry.value }}
                    </p>
                  </template>
                </div>
                <div v-if="secondaryAgentOptions?.length">
                  <div
                    v-if="client.assignedSecondaryAgent"
                    class="text-base pt-3"
                  >
                    Assign to team member:
                  </div>
                  <c-dropdown
                    class="mt-3 border-gray-300"
                    variant="demo"
                    placeholder="Assign to team member"
                    :value="client.assignedSecondaryAgent"
                    :options="secondaryAgentOptions"
                    @input="assignAgent"
                  />
                </div>
              </div>
            </div>
          </div>

          <lead-details-tabs
            :client="client"
            :full-name="fullName"
            @submit="handleSubmit"
          />
        </div>
      </article>
    </main>
  </div>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  SearchFilterControls,
  Spinner,
  Breadcrumbs,
} from '@/components';
import { CDropdown } from '@/components/inputs';
import { CLink } from '@/components/controls';
import {
  ChevronleftIcon,
  MailIcon,
  PhoneIcon,
  GeotagIcon,
  DollarIcon,
  HouseIcon,
} from '@/components/icons';
import { LeadDetailsTabs } from '@/components/lead-details';
import { errors } from '@/constants';
import {
  getBadges,
  formatPhoneNumber,
  getTags,
  isIncludedSoldTag,
  getIPayText,
  getStatusTag,
  getCurrentPriceFromClient,
  getCurrentPriceLabelFromClient,
} from '@/utils';

export default {
  name: 'LeadDetails',
  components: {
    SearchFilterControls,
    Spinner,
    CLink,
    ChevronleftIcon,
    MailIcon,
    PhoneIcon,
    GeotagIcon,
    DollarIcon,
    Breadcrumbs,
    HouseIcon,
    LeadDetailsTabs,
    CDropdown,
  },
  data() {
    return {
      isLoadingClient: false,
      client: {},
      tags: [],
      formSubmitted: false,
    };
  },
  computed: {
    ...mapState('agent', ['agent']),
    ...mapGetters('agent', ['secondaryAgentOptions']),
    fullName: vm => `${vm.client.firstName} ${vm.client.lastName}`,
    emailAddress: vm => vm.client.emails?.[0]?.emailAddress,
    phoneNumber: vm => vm.client.phones?.[0]?.phoneNumber,
    iconsClass: () => '-ml-1 mr-2 h-5 w-5 text-gray-500 fill-current',
    clientTypeColor: vm => (vm.client.clientType === 'SELLER' ? 'green' : 'blue'),
    statusTag: vm => getStatusTag(vm.client),
    infoToDisplay: vm => [
      {
        display: vm.client.address || vm.client.location,
        name: 'address',
        value: (() => {
          const {
            unitNumber, address, location, city, state, zipCode,
          } = vm.client;
          if (address) {
            const unit = unitNumber ? `, Unit ${unitNumber}` : '';
            const csz = city && state && zipCode ? `, ${city}, ${state} ${zipCode}` : '';
            return address + unit + csz;
          }
          return location;
        })(),
        icon: 'GeotagIcon',
      },
      {
        display: getCurrentPriceLabelFromClient(vm.client),
        name: 'price',
        value: `${getCurrentPriceLabelFromClient(vm.client)}: ${getCurrentPriceFromClient(vm.client)}`,
        icon: 'DollarIcon',
      },
      {
        display: vm.client.emails,
        name: 'email',
        value: vm.client.emails?.map(e => e.emailAddress).join(', '),
        icon: 'MailIcon',
      },
      {
        display: vm.client.phones,
        name: 'phone',
        value: vm.client.phones?.map(p => formatPhoneNumber(p.phoneNumber)).join(', '),
        icon: 'PhoneIcon',
      },
    ],
    displayedTags: vm => {
      const {
        needUpdate, nextFollowUp, offer, offerStatus,
      } = vm.client;
      const tags = getTags(vm.client, vm.formSubmitted);
      if (vm.formSubmitted || !needUpdate) return tags;

      const badges = getBadges({ nextFollowUp, offer, offerStatus });
      return [...tags, ...badges];
    },
    isBuyer: vm => vm.client.clientType === 'BUYER',
    isSold: vm => isIncludedSoldTag(vm.displayedTags),
    iPayText: vm => getIPayText(vm.client),
  },
  watch: {
    // eslint-disable-next-line object-shorthand
    '$route.params.id'() {
      this.getClientDetails();
    },
  },
  created() {
    this.getClientDetails();
  },
  methods: {
    ...mapActions('client', ['getClient']),
    ...mapActions('agent', ['assignSecondaryAgent']),
    async getClientDetails() {
      const { name, params } = this.$route;
      const options = { referralId: params.id, agentId: this.agent?.id, name };
      try {
        this.isLoadingClient = true;
        this.client = await this.getClient(options);
        if (this.client.noFurtherFollowUpRequired) {
          throw new Error(errors.notAvailableForUpdates);
        }
      } catch ({ message }) {
        this.$toast.error(message);
        this.$router.push('/my-referrals');
      } finally {
        this.isLoadingClient = false;
      }
    },
    async assignAgent(secondaryId) {
      const id = secondaryId || this.agent?.id;
      const { name, params } = this.$route;
      const options = { referralId: params.id, secondaryAgentId: id, profileType: name };
      try {
        this.isLoadingClient = true;
        await this.assignSecondaryAgent(options);
        await this.getClientDetails();
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isLoadingClient = false;
      }
    },
    handleSubmit() {
      this.formSubmitted = true;
    },
  },
};
</script>
