import Vue from 'vue';
import { defaultOrderByProperty, defaultOrderByDirection } from '@/constants/';
import {
  conditionCallbackByFlag,
  searchPersons,
  groupPersons,
  sortClientResults,
} from './utils';
import { directorySearchCriteria } from './constants';

const state = {
  flags: {
    sellers: false,
    pending: false,
    buyers: false,
    active: false,
    offMarket: false,
    needsUpdates: false,
    idealOffer: false,
  },
  hideFlags: {
    sold: true,
    lost: true,
  },
  hideSold: true,
  hideLost: true,
  searchDirection: defaultOrderByDirection,
  orderByProperty: defaultOrderByProperty,
  searchQuery: '',
};

const getters = {
  filteredClients: (state, getters, rootState, rootGetters) => {
    const clients = rootGetters['agent/clients'];
    const { activeFlags, hideFlags } = getters;
    let flagFiltered = clients.filter(client => {
      return activeFlags.every(flag => conditionCallbackByFlag[flag](client));
    });
    flagFiltered = flagFiltered.filter(f => f.needUpdate || !hideFlags.sold || !conditionCallbackByFlag.sold(f))
      .filter(f => f.needUpdate || !hideFlags.lost || !conditionCallbackByFlag.lost(f));
    const sortedClients = sortClientResults(flagFiltered, state.orderByProperty, state.searchDirection);
    return sortedClients;
  },
  impersonationUsers: (state, getters, rootState) => {
    const { usersToImpersonate } = rootState.client;
    const searchResults = searchPersons(usersToImpersonate, state.searchQuery);
    return groupPersons(searchResults);
  },
  directoryUsers: (state, getters, rootState, rootGetters) => {
    const clients = rootGetters['agent/clients'];
    const sortedClients = sortClientResults(clients, '', 'asc');
    const searchResults = searchPersons(sortedClients, state.searchQuery, directorySearchCriteria);
    return groupPersons(searchResults);
  },
  activeFlags: state => {
    const flags = Object.keys(state.flags);
    return flags.filter(key => state.flags[key] === true);
  },
  hideFlags: state => {
    return state.hideFlags;
  },
  needUpdateCount: (state, getters, rootState, rootGetters) => {
    const clients = rootGetters['agent/clients'];
    const needUpdateClients = clients.filter(client => client.needUpdate);
    return needUpdateClients.length;
  },
};

const actions = {
  setGroupFlag({ commit }, payload) {
    commit('setGroupFlag', payload);
  },
  setFlag({ commit }, payload) {
    commit('setFlag', payload);
  },
  setHideFlag({ commit }, payload) {
    commit('setHideFlag', payload);
  },
  setSearchQuery({ commit }, payload) {
    commit('setSearchQuery', payload);
  },
  clearFilters({ commit }) {
    commit('clearFilters');
  },
  setOrderByProperty({ commit }, payload) {
    commit('setOrderByProperty', payload);
  },
  toggleOrderDirection({ commit }) {
    commit('toggleOrderDirection');
  },
};

const mutations = {
  clearFilters(state) {
    const flagNames = Object.keys(state.flags);
    flagNames.forEach(flag => {
      Vue.set(state.flags, flag, false);
    });
  },
  setFlag(state, { flag, value }) {
    Vue.set(state.flags, flag, value);
  },
  setHideFlag(state, { flag, value }) {
    Vue.set(state.hideFlags, flag, value);
  },
  setGroupFlag(state, {
    group, flag, value,
  }) {
    group.forEach((item) => {
      Vue.set(state.flags, item.property, false);
    });
    Vue.set(state.flags, flag, value);
  },
  setSearchQuery(state, searchQuery) {
    state.searchQuery = searchQuery;
  },
  setOrderByProperty(state, orderByProperty) {
    state.orderByProperty = orderByProperty;
  },
  toggleOrderDirection(state) {
    state.searchDirection = state.searchDirection === 'asc' ? 'desc' : 'asc';
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
