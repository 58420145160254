import { isEmpty } from 'lodash';

export const getUserToken = () => JSON.parse(localStorage.getItem('token'));

export const getAdminToken = () => JSON.parse(localStorage.getItem('adminToken'));

export const setUserToken = (token) => {
  const oldToken = getUserToken();

  if (!isEmpty(token) && token !== oldToken) {
    localStorage.setItem('token', JSON.stringify(token));
  }
};

export const setAdminToken = (token) => {
  const oldToken = getAdminToken();

  if (!isEmpty(token) && token !== oldToken) {
    localStorage.setItem('adminToken', JSON.stringify(token));
  }
};

export const removeUserToken = () => localStorage.removeItem('token');

export const removeAdminToken = () => localStorage.removeItem('adminToken');

export const setClickId = (id) => localStorage.setItem('clickId', id);

export const getClickId = () => {
  const id = localStorage.getItem('clickId');
  localStorage.removeItem('clickId');
  return id;
};
