import {
  Login,
  ResetPassword,
  EmailVerification,
  NotFound,
  Maintenance,
} from '@/views';

import {
  PrimaryLayout,
} from '@/layouts';

import { primaryLayoutChildren } from './primaryLayout';

export default [
  {
    path: '/login',
    name: 'Login',
    components: {
      layout: Login,
    },
  },
  {
    path: '/reset-password',
    name: 'Reset password',
    components: {
      layout: ResetPassword,
    },
  },
  {
    path: '/maintenance',
    name: 'Maintenance',
    components: {
      layout: Maintenance,
    },
  },
  {
    path: '/email-verification',
    name: 'Email Verification',
    components: {
      layout: EmailVerification,
    },
  },
  {
    path: '/',
    components: {
      layout: PrimaryLayout,
    },
    meta: {
      requiresAuth: true,
    },
    children: primaryLayoutChildren,
  },
  {
    path: '*',
    name: 'NotFound',
    components: {
      layout: NotFound,
    },
  },
];
