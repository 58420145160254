import axios from 'axios';
import qs from 'qs';
import { getUserToken, getAdminToken } from '@/utils/localStorageControls';
import { formatErrors } from '@/utils/errorMessages';

const envBaseUrl = process.env.VUE_APP_BASE_URL;

const api = axios.create({
  baseURL: envBaseUrl || 'https://dev-partner-api.idealagent.com',
  paramsSerializer: params => qs.stringify(params, { indices: false }),
});

const commonContentType = 'application/json';
const patchContentType = 'application/json-patch+json';

api.interceptors.request.use(config => {
  if (config.noToken) return config;

  const localConfig = { ...config };
  const tokenType = localConfig.tokenType || 'user';
  const contentType = config.method === 'patch' ? patchContentType : commonContentType;

  const token = (() => {
    switch (tokenType) {
      case 'user': return getUserToken();
      case 'admin': return getAdminToken();
      default: return '';
    }
  })();

  localConfig.headers = {
    'Content-Type': config.contentType || contentType,
    token,
  };
  return localConfig;
});

api.interceptors.response.use(null, error => {
  const message = formatErrors(error.response);
  throw new Error(message);
});

export { api };
