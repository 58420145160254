<template>
  <div class="flex flex-1 overflow-hidden">
    <spinner
      v-if="shouldDisplaySpinner"
      fullscreen
    />
    <div
      v-else
      class="flex flex-col flex-1 md:flex-row-reverse"
    >
      <directory-list
        :grouped-clients="impersonationUsers"
        search-placeholder="Search for Impersonator"
        @select-user="loadUser"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Spinner from '@/components/Spinner.vue';
import DirectoryList from '@/components/DirectoryList.vue';

export default {
  name: 'UserImpersonation',
  components: {
    Spinner,
    DirectoryList,
  },
  data() {
    return {
      isFetchingUsers: false,
      users: [],
      groupedUsers: {},
      isFetchingUser: false,
    };
  },
  computed: {
    shouldDisplaySpinner: vm => vm.isFetchingUsers || vm.isFetchingUser,
    ...mapState('agent', ['user']),
    ...mapGetters('filters', ['impersonationUsers']),
  },
  async created() {
    try {
      this.isFetchingUsers = true;
      await this.getUsersToImpersonate();
    } catch ({ message }) {
      this.$toast.error(message);
    } finally {
      this.isFetchingUsers = false;
    }
  },
  methods: {
    ...mapActions('client', ['getUsersToImpersonate', 'getImpersonationToken']),
    ...mapActions('agent', ['getUserDetails', 'getAgentDetails', 'getAgentAsa', 'getAgentSummary', 'getUserTours']),
    ...mapActions('auth', ['setAdminFlags']),

    async loadUser(user) {
      try {
        this.isFetchingUser = true;
        const isAdmin = true;
        const { user: admin } = await this.getUserDetails(isAdmin); // needed to get admin info
        const params = {
          realUsername: admin.email,
          impersonatedUsername: user.email,
        };
        await this.getImpersonationToken(params);
        const { impersonatedUser: { firstName, lastName } } = await this.getUserDetails();
        await this.getAgentDetails();
        await this.getAgentAsa();
        await this.getAgentSummary();
        await this.getUserTours(this.user.id);
        const adminData = {
          setAdmin: true,
          setIsUserSelected: true,
        };
        this.setAdminFlags(adminData);
        this.$toast.success(`Impersonating user ${firstName} ${lastName}`);
        this.$router.push({ path: '/' });
      } catch ({ message }) {
        this.$toast.error(message);
      } finally {
        this.isFetchingUser = false;
      }
    },
  },

};
</script>
