export const alertThemes = {
  success: {
    color: 'green',
    icon: 'CheckCircleIcon',
  },
  info: {
    color: 'light-blue',
    icon: 'InformationCircleIcon',
  },
  caution: {
    color: 'orange',
    icon: 'ExclamationTriangleIcon',
  },
  warning: {
    color: 'amber',
    icon: 'ExclamationTriangleIcon',
  },
  danger: {
    color: 'rose',
    icon: 'XCircleIcon',
  },
};

export const alerts = [
  {
    name: 'AGENT_PAUSED',
    text: 'New Referrals have been temporarily paused to give you an opportunity to bring your past-due communications up to date. Please contact your Account Executive for assistance. Thank you.',
    ...alertThemes.warning,
  },
];
